import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Glitch from "../effect/Glitch"
import TextInsert from "../effect/TextInsert"
gsap.registerPlugin(ScrollTrigger)

const isMobile = ('ontouchstart' in document.documentElement && navigator.userAgent.match(/Mobi/))

// 頁面載入完成
Pace.once('done', () => {
  let title = new TextInsert(document.querySelector('.section-intro .title'));
  let p = new TextInsert(document.querySelector('.section-intro .content'));
  
  let item = document.querySelector('.section-intro .glitch');
  const imgURL = item.getElementsByTagName('img')[0].getAttribute('src');
  const glitch = new Glitch(item, imgURL);

  // gsap.set('.section-intro title, .section-intro p', { opacity: 0 })
  let tl = gsap.timeline({
    paused: true
    // scrollTrigger: {
    //   trigger: '.section-intro',
    //   start: 'center-=10 center',
    //   toggleActions: 'play none none none',
    // },
  });
  tl.add(title.tl, "+=1.5");
  tl.add(p.tl, "-=0.5");
  tl.fromTo('.intro__kv', { 
    opacity: 0 
  }, {
    duration: 2,
    opacity: 1
  })
  
  if(!isMobile) {
    tl.call(() => {glitch.playTwice()});
  }

  // cut 進場
  tl.fromTo('.section-intro .speed-cut1', {
    "clip-path": "polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%)"
  }, {
    duration: .5,
    "clip-path": "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
  });

  
  tl.to('.section-intro .speed-cut1', {
    duration: .5,
    "clip-path": "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)"
  });

  tl.fromTo('.section-intro .speed-cut2', {
    "clip-path": "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)"
  }, {
    duration: .5,
    "clip-path": "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
  }, "<");

  tl.to('.section-intro .speed-cut2', {
    duration: .5,
    "clip-path": "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)"
  });

  tl.fromTo('.section-intro .speed-cut3', {
    "clip-path": "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)"
  }, {
    duration: .5,
    "clip-path": "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
  }, "<");

  if(!isMobile) {
    tl.play()
  } else {
    item.addEventListener('init', ()=>{
      tl.play();
    });
  }

});