import Swiper, { EffectFade } from 'swiper';
import "swiper/swiper-bundle.min.css";
import TextInsert from '../effect/TextInsert';

import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.registerEffect({
  name:"counter",
  extendTimeline:true,
  defaults:{
    end:0,
    duration:0.5,
    ease:"power1",
    increment:1,
  },
  effect: (targets, config) => {
    let tl = gsap.timeline()
    let num = targets[0].innerText.replace(/\,/g,'')
    targets[0].innerText = num
    
    tl.to(targets, {
      duration:config.duration, 
      innerText:config.end, 
      snap:{innerText:config.increment},
      ease:config.ease}, 0)
    return tl
  }
});

(function(){
  let gsaps = [];

  for (let i = 0; i < 3; i++) {
    if( i == 0) {
      gsaps[i] = gsap.timeline({
        paused: true,
        scrollTrigger: {
          trigger: '.section-experience',
          start: 'top bottom',
          toggleActions: 'play none none none',
        }
      });
    } else {
      gsaps[i] = gsap.timeline({
        paused: true
      });
    }
  }

  gsaps[0]
    .add(new TextInsert(document.querySelector('.section-experience .slide0 .h3')).tl)
    .add(new TextInsert(document.querySelector('.section-experience .slide0 p')).tl, "-=0.5")

    .from('.section-experience .bar0 .line-short', {
      duration: 1,
      width: 0
    }, "-=.5")
    .counter('.section-experience .bar0 .counter', {
      end: 4804,
      duration: 1
    }, "<")
    .from('.section-experience .bar0 .line-long', {
      duration: 1,
      width: 0
    }, "-=.95")

    .from('.section-experience .bar1 .line-short', {
      duration: 1,
      width: 0
    }, "-=0.8")
    .counter('.section-experience .bar1 .counter', {
      end: 4804,
      duration: 1
    }, "<")
    .from('.section-experience .bar1 .line-long', {
      duration: 1,
      width: 0
    }, "-=.95")

    .from('.section-experience .bar2 .line-short', {
      duration: 1,
      width: 0
    }, "-=0.8")
    .counter('.section-experience .bar2 .counter', {
      end: 4804,
      duration: 1
    }, "<")
    .from('.section-experience .bar2 .line-long', {
      duration: 1,
      width: 0
    }, "-=.95")

    .from('.section-experience .bar3 .line-short', {
      duration: 1,
      width: 0
    }, "-=0.8")
    .counter('.section-experience .bar3 .counter', {
      end: 1148,
      duration: 1
    }, "<")
    .from('.section-experience .bar3 .line-long', {
      duration: 1,
      width: 0
    }, "-=.95")

  gsaps[1]
    .add(new TextInsert(document.querySelector('.section-experience .slide1 .h3')).tl)
    .add(new TextInsert(document.querySelector('.section-experience .slide1 p')).tl, "-=0.5")
    .fromTo('.section-experience .slide1 img', {
      yPercent: 50,
      opacity: 0
    }, {
      duration: 0.5,
      yPercent: 0,
      opacity: 1
    })

  gsaps[2]
    .add(new TextInsert(document.querySelector('.section-experience .slide2 .h3')).tl)
    .add(new TextInsert(document.querySelector('.section-experience .slide2 p')).tl, "-=0.5")
    .fromTo('.section-experience .slide2 img', {
      yPercent: 50,
      opacity: 0
    }, {
      duration: 0.5,
      yPercent: 0,
      opacity: 1
    })

  const experienceSwiper = new Swiper('.section-experience .swiper', {
    modules: [EffectFade],
    // autoHeight: true,
    effect: 'fade',
    allowTouchMove: false,
    fadeEffect: {
      crossFade: true
    },
    on: {
      slideChange() {
        // console.log(this.realIndex);
        gsaps[this.realIndex].restart()
      },
    }
  });

  function change() {
    experienceSwiper.slideTo(this.dataset.to)
    document.querySelectorAll('.exp_btn.active').forEach((item)=>{
      item.classList.remove('active')
    })
    this.classList.add('active')
  }
  
  document.querySelectorAll('.exp_btn').forEach((item) => { 
    item.addEventListener('click', change)
    item.addEventListener('touchstart', change)
  })
})()