import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

export default class TextInsert {
  constructor(el) {
    // create wrap div
    this._el = el

    let div = document.createElement('div')
    div.className = "overflow-hidden"
    el.parentNode.insertBefore(div, el)
    div.appendChild(el)
    this.#createAnim()
  }

  #createAnim() {
    if(!this._el.dataset.autoplay) {
      this._tl = gsap.fromTo(this._el, {
        // opacity: 0,
        xPercent: -100,
      }, {
        duration: 1,
        delay: this._el.dataset.delay || 0,
        // opacity: 1,
        xPercent: 0,
        scrollTrigger: {
          trigger: this._el,
          start: 'top bottom',
          // end: 'bottom bottom',
          toggleActions: 'play none none none',
          // markers: true
        },
      });
    } else {
      this._tl = gsap.fromTo(this._el, {
        // opacity: 0,
        xPercent: -100,
      }, {
        // paused: true,
        duration: 1,
        // delay: this._el.dataset.delay || 0,
        // opacity: 1,
        xPercent: 0
      });
    }
  }

  play() {
    this._tl.play()
  }

  reset() {
    this._tl.pause(0)
  }

  get tl() {
    return this._tl
  }
}