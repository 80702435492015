import * as PIXI from 'pixi.js';
import { RGBSplitFilter } from '@pixi/filter-rgb-split';
import { GlitchFilter } from '@pixi/filter-glitch';

import { gsap } from 'gsap';

let isMobile = /Mobi/i.test(window.navigator.userAgent);

export default class Glitch {
  constructor(el, img, hoverAble = false) {
    PIXI.utils.skipHello()

    // create stage
    this._el = el
    this._hoverAble = (hoverAble === 'true')

    this._app = new PIXI.Application({
      view: this._el,
      backgroundAlpha: 0,
    })

    this._app.loader
      .add(img)
      .load(()=>{
      this.#createSprite(img)
    })

    // this.#createSprite(img)
  }

  #createSprite(img) {
    // let texture = this._app.loader.resources[img].texture;
    const texture = PIXI.Texture.from(img);
    this._img = new PIXI.Sprite(texture);
    this._app.renderer.resize(texture.width, texture.height);
    this._app.stage.addChild(this._img);

    // create all filters, rgb split and glitch slices
    this._img.filters = [
      new RGBSplitFilter(), 
      new GlitchFilter()
    ];
    this.#resetFilters();
    this.#createGlitchTL();

    if(this._hoverAble) {
      this._app.stage.interactive = true;
      this._app.stage.buttonMode = true;
      this._app.stage.hitArea = this._app.renderer.screen;
      
      if(this._el.dataset.href) {
        this._app.stage.on('pointerover', ()=>{ this.playOnce() });
        this._app.stage.on('pointerout', ()=>{ this.#pause() });
      }

      this._app.stage.on('pointerdown', () => {
        if(this._el.dataset.href) {
          if(isMobile) {
            window.location.assign(this._el.dataset.href);
          } else {
            window.open(this._el.dataset.href, "_blank");
          }
        } else {
          this._tl.repeat(0);
          this._tl.restart();
        }
      })
    }

    this._el.dispatchEvent(new Event('init'));
  }

  #resetFilters() {
    // reset rgb split
    this._img.filters[0].red.x = 0;
    this._img.filters[0].red.y = 0;
    this._img.filters[0].green.x = 0;
    this._img.filters[0].green.y = 0;
    this._img.filters[0].blue.x = 0;
    this._img.filters[0].blue.y = 0;

    // reset glitch
    this._img.filters[1].slices = 0;
    this._img.filters[1].offset = 20;
  }
  
  randomIntFromInterval(min, max) {
    return Math.random() * (max - min + 1) + min;
  }
  
  #createGlitchTL() {
    const THAT = this;

    if( this._hoverAble ) {
      this._tl = gsap.timeline({
        repeat: -1,
        repeatDelay: this.randomIntFromInterval(0, 3),
        paused: true
      })
    } else {
      this._tl = gsap.timeline({
        repeat: 1,
        repeatDelay: 3,
        paused: true
      })
    }

    this._tl.to(this._img.filters[0].red, {
      duration: 0.2,
      x: this.randomIntFromInterval(-15, 15),
      y: this.randomIntFromInterval(-15, 15)
    });

    this._tl.to(this._img.filters[0].red, {
      duration: 0.01,
      x: 0,
      y: 0
    });

    this._tl.to(this._img.filters[0].blue, {
      duration: 0.2,
      x: this.randomIntFromInterval(-15, 15),
      y: 0,
      onComplete() {
        
        THAT._img.filters[1].slices = 20
        THAT._img.filters[1].direction = THAT.randomIntFromInterval(-75, 75)

        // console.log(THAT._img.filters[1].slices)
        
      }
    }, '-=0.2');

    this._tl.to(this._img.filters[0].blue, {
      duration: 0.1,
      x: this.randomIntFromInterval(-15, 15),
      y: this.randomIntFromInterval(-5, 5),
      onComplete() {

        THAT._img.filters[1].slices = 12
        THAT._img.filters[1].direction = THAT.randomIntFromInterval(-75, 75)
        
      }
    });

    this._tl.to(this._img.filters[0].blue, {
      duration: 0.01,
      x: 0,
      y: 0,
      onComplete() {

        THAT._img.filters[1].slices = 0
        THAT._img.filters[1].direction = 0

      }
    });

    this._tl.to(this._img.filters[0].green, {
      duration: 0.2,
      x: this.randomIntFromInterval(-15, 15),
      y: 0
    }, '-=0.2');

    this._tl.to(this._img.filters[0].green, {
      duration: 0.1,
      x: this.randomIntFromInterval(-20, 20),
      y: this.randomIntFromInterval(-15, 15)
    });

    this._tl.to(this._img.filters[0].green, {
      duration: 0.01,
      x: 0,
      y: 0
    });

    // this._tl.call(()=>{ console.log(this._el) })
    this._tl.timeScale(1.2);
  }

  #play() {
    if(this._tl) {
      this._tl.play();
    }
  }

  #pause() {
    if(this._tl) {
      this.#resetFilters();
      this._tl.pause(0);
    }
  }

  playOnce() {
    if(this._tl) {
      this._tl.repeat(0);
      this._tl.restart();
    }
  }

  playTwice() {
    if(this._tl) {
      this._tl.repeat(1);
      this._tl.restart();
    }
  }
}