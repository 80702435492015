import './vendors/fancyapps'
import SmoothScroll from "smoothscroll-for-websites"

import './section/intro'
import './section/experience'
import './section/flexible'
import './section/speed'
import './section/boundaries'

import './components/settingAllEffect'
import './components/bgMove'

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}