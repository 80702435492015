import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

export default class ImgClipRight {
  constructor(el, autoplay = true) {
    this._el = el
    this._autoplay = autoplay
    this.#createAnim()
  }

  #createAnim() {
    if(this._autoplay) {
      this._tl = gsap.fromTo(this._el, {
        "clip-path": "polygon(0 0, 0 0, 0 100%, 0 100%)"
      }, {
        duration: 1,
        delay: 0,
        "clip-path": "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
        scrollTrigger: {
          trigger: this._el,
          start: 'top 100%',
          toggleActions: 'play none none none',
          // markers: true
        },
      });
    } else {
      this._tl = gsap.fromTo(this._el, {
        "clip-path": "polygon(0 0, 0 0, 0 100%, 0 100%)"
      }, {
        // paused: true,
        duration: 1,
        "clip-path": "polygon(0 0, 100% 0, 100% 100%, 0 100%)"
      });
    }
  }

  play() {
    this._tl.play();
  }

  get tl() {
    return this._tl;
  }
}