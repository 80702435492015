import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import TextInsert from '../effect/TextInsert';
gsap.registerPlugin(ScrollTrigger);

const isMobile = ('ontouchstart' in document.documentElement && navigator.userAgent.match(/Mobi/))

if(isMobile) {
  ScrollTrigger.config({
    autoRefreshEvents: "DOMContentLoaded,load"
  })
}

let tl = gsap.timeline({
  scrollTrigger: {
    trigger: '.section-speed',
    start: 'top top',
    // end: isMobile ? '+=600%' : '+=600%',
    end: '+=600%',
    pin: true,
    scrub: true,
    // pinSpacing: 'margin'
    // onRefresh: () => console.log("onRefresh"),
  }
})

// Cut1
let cut1_title = new TextInsert(document.querySelector('.section-speed .cut1 .title'))
let cut1_content = new TextInsert(document.querySelector('.section-speed .cut1 .content'))

tl.fromTo('.section-speed', {
  opacity: 0,
}, {
  duration: .01,
  opacity: 1
})
tl.from('.section-speed .cut1-2', {
  duration: 10,
  opacity: 0,
}, "+=2")
tl.add(cut1_title.tl)
tl.add(cut1_content.tl)
tl.from('.section-speed .cut1-3', {
  duration: 10,
  opacity: 0,
}, "+=2")
.to({}, {duration: 6})

tl.to('.section-speed .cut1', {
  duration: 4,
  opacity: 0,
})

// Cut2
let cut2_title = new TextInsert(document.querySelector('.section-speed .cut2__title'))
let cut2_content = new TextInsert(document.querySelector('.section-speed .cut2__content'))

tl.from('.section-speed .cut2', {
  opacity: 0,
})
tl.from('.section-speed .cut2-1', {
  opacity: 0,
}, "+=2")
tl.add(cut2_title.tl)
tl.add(cut2_content.tl)
tl.from('.section-speed .cut2-2', {
  duration: 4,
  opacity: 0,
}, "+=2")
tl.from('.section-speed .cut2-3', {
  duration: 4,
  opacity: 0,
}, "+=2")
tl.from('.section-speed .cut2-4', {
  duration: 4,
  opacity: 0,
}, "+=2")
.to({}, {duration: 6})

// Cut3
let cut3_title = new TextInsert(document.querySelector('.section-speed .cut3__title'))
let cut3_content = new TextInsert(document.querySelector('.section-speed .cut3__content'))

tl.to( ".section-speed .cut2-2, .section-speed .cut2-3, .section-speed .cut2-4, .section-speed .cut2__title, .section-speed .cut2__content", {
  duration: 4,
  opacity: 0,
})

tl.add(cut3_title.tl)
tl.add(cut3_content.tl)
tl.from('.section-speed .cut3-1', {
  duration: 6,
  opacity: 0,
}, "+=2")
.to({}, {duration: 6})

tl.to('.section-speed', {
  duration: 5,
  opacity: 0,
})