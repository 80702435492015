import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

import Glitch from '../effect/Glitch'
import ImgClipRight from '../effect/ImgClipRight'
import TextInsert from '../effect/TextInsert'

// const isMobile = ('ontouchstart' in document.documentElement && navigator.userAgent.match(/Mobi/))

// if(!isMobile) {
//   gsap.utils.toArray(".panel").forEach((panel, i, array) => {
//     ScrollTrigger.create({
//       trigger: panel,
//       start: "top top",
//       end: (i === array.length - 1) ? "+=100%":"+=200%",
//       // end: "+=200%",
//       pin: true,
//       // pinSpacing: false
//       pinSpacing: 'margin'
//     })
//   })
// }

gsap.utils.toArray( '[data-effect*=text-insert]' ).forEach((item) => {
  new TextInsert(item)
})

document.querySelectorAll( '[data-effect*=glitch]' ).forEach((item) => {
  const imgURL = item.getElementsByTagName('img')[0].getAttribute('src')
  new Glitch(item, imgURL, item.dataset.hoverAble)
})

gsap.utils.toArray( '[data-effect*=clip-right]' ).forEach((item) => {
  new ImgClipRight(item)
})

gsap.utils.toArray( '[data-effect*=fade]' ).forEach( ( ele ) => {
  const direction = ele.dataset.effect.split('-')[1]
  const proxy = {
    up: [0, 50],
    down: [0, -50],
    left: [50, 0],
    right: [-50, 0],
  }
  gsap.fromTo( ele, {
    opacity: 0,
    xPercent: proxy[direction][0],
    yPercent: proxy[direction][1],
  }, {
    duration: 0.5,
    opacity: 1,
    xPercent: 0,
    yPercent: 0,
    scrollTrigger: {
      trigger: ele,
      start: 'top 90%',
      toggleActions: 'play none none none',
    },
  })
})