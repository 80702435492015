import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";
import Swiper, { Pagination, Autoplay } from 'swiper';
import "swiper/swiper-bundle.min.css";
import "../../css/vendors/swiper.css"

import { gsap } from "gsap";
import Glitch from "../effect/Glitch";
Swiper.use([Pagination, Autoplay]);

const isMobile = ('ontouchstart' in document.documentElement && navigator.userAgent.match(/Mobi/))

const swiper = new Swiper('.swiper--pagination', {
  slidesPerView: 'auto',
  // autoplay: true,
  pagination: {
    el: '.swiper--pagination .swiper-pagination',
    clickable: true
  }
})

function changeState() {
  if(swiper.autoplay.running) {
    gsap.to('#dialog-content2 .play', {autoAlpha: 0})
    gsap.to('#dialog-content2 .pause', {autoAlpha: 1})
  } else {
    gsap.to('#dialog-content2 .play', {autoAlpha: 1})
    gsap.to('#dialog-content2 .pause', {autoAlpha: 0})
  }
}

swiper.on('slideChange', changeState);

document.querySelector('#dialog-content2 .pause').addEventListener('click', () => {
  swiper.autoplay.stop()
  changeState()
})

document.querySelector('#dialog-content2 .play').addEventListener('click', () => {
  swiper.autoplay.start()
  changeState()
})

;(function(){
  Fancybox.defaults.ScrollLock = false;
  let isOpen = false;
  document.querySelectorAll('.section-flexible .btns').forEach((item) => {
    item.addEventListener('mouseenter', () => {
      if(!isMobile) {
        gsap.fromTo(item.dataset.hover, {
          opacity: 0,
          scale: .8
        }, {
          duration: .8,
          opacity: 1,
          scale: 1,
          ease: "elastic.out(1, 0.3)"
        })
      }
    })

    item.addEventListener('mouseleave', () => {
      if(!isMobile) {
        gsap.killTweensOf(item.dataset.hover)
        gsap.fromTo(item.dataset.hover, {
          opacity: 1,
          scale: 1
        }, {
          duration: .3,
          opacity: 0,
          scale: .8
        })
      }
    })

    item.addEventListener('click', () => {
      // console.log(item.dataset.src);
      if(isOpen) {
        return
      }
      isOpen = true;

      let tl = gsap.timeline();
      
      if(isMobile) {
        tl.fromTo(item.dataset.hover, {
          opacity: 0,
          scale: .8
        }, {
          duration: .8,
          opacity: 1,
          scale: 1,
          ease: "elastic.out(1, 0.3)"
        })
        tl.to({}, {duration: .5})
      }

      tl.call(()=>{
        swiper.autoplay.start()
        changeState()
        // console.log('delayedCall');
        Fancybox.show([{ 
          src: item.dataset.src, 
          type: "inline"
        }], {
          showClass: "fancybox-custom-zoomIn",
          hideClass: "fancybox-custom-zoomOut",
          dragToClose: false,
          on: {
            shouldClose: () => {
              swiper.autoplay.stop()
              gsap.set('.section-flexible .btns-hover', { opacity:0 })

              isOpen = false
            }
          }
        });
      });
    });
  });
})();