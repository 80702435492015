import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const tl = gsap.timeline({
  scrollTrigger: {
    trigger: '.section-boundaries .graph',
    start: 'top bottom',
    end: '+=100%',
    // markers: true,
    // toggleActions: 'play pause play pause',
    toggleActions: 'none none none none',
    onEnter: () => changeState(true),
    onLeave: () => changeState(false),
    onEnterBack: () => changeState(true),
    onLeaveBack: () => changeState(false),
  },
  repeat: -1,
  paused: true
})

tl.set('.section-boundaries .withvpn, .section-boundaries .withoutvpn', {
  duration: 2,
  opacity: 1
})
tl.fromTo('.section-boundaries .withoutvpn', {
  "clip-path": "polygon(0 0, 0 0, 0 100%, 0 100%)"
}, {
  duration: 4,
  "clip-path": "polygon(0 0, 100% 0, 100% 100%, 0 100%)"
})
tl.fromTo('.section-boundaries .withvpn', {
  "clip-path": "polygon(0 0, 0 0, 0 100%, 0 100%)"
}, {
  duration: 4,
  "clip-path": "polygon(0 0, 100% 0, 100% 100%, 0 100%)"
}, "-=1")
tl.fromTo('.section-boundaries .internet', {
  opacity: 0,
}, {
  duration: 1,
  opacity: 1
}, "-=1")
tl.to('.section-boundaries .withvpn, .section-boundaries .withoutvpn, .section-boundaries .internet', {
  duration: 2,
  opacity: 0
}, "+=1")

// tl.call(()=>{console.log('call');})
// tl.eventCallback('onUpdate', ()=>{
//   console.log("tl.paused():", tl.paused());
// })

let isPlay = true
gsap.set('.section-boundaries .pause', {opacity: 0})
function changeState(toPlay, force = false) {
  if(!isPlay && !force) return

  if(toPlay) {
    gsap.to('.section-boundaries .play', {opacity: 1})
    gsap.to('.section-boundaries .pause', {opacity: 0})
  } else {
    gsap.to('.section-boundaries .play', {opacity: 0})
    gsap.to('.section-boundaries .pause', {opacity: 1})
  }

  tl.paused(!toPlay)
}

document.querySelector('.section-boundaries .graph').addEventListener('click', ()=>{
  if(isPlay) {
    isPlay = false
  } else {
    isPlay = true
  }
  changeState(isPlay, true)
})