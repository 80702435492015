import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

(function(){
  ScrollTrigger.create({
    trigger: ".content",
    start: "top top",
    end: "bottom bottom",
    pin: ".wrap-bg"
  });

  ScrollTrigger.create({
    trigger: ".section-intro",
    start: "top bottom",
    end: "1px top",
    // markers: true,
    onToggle: self => { 
      // console.log("toggled, isActive:", self.isActive)
      if(self.isActive) {
        gsap.to(".bg-img", {
          duration: 2,
          xPercent: 0,
          yPercent: 0,
          scale: 1,
          opacity: .5,
          ease: 'power1',
          overwrite: 'auto'
        })
      }
    },
  });

  ScrollTrigger.create({
    trigger: ".section-experience",
    start: "top bottom",
    end: "1px top",
    // markers: true,
    onToggle: self => { 
      // console.log("toggled, isActive:", self.isActive)
      if(self.isActive) {
        gsap.to(".bg-img", {
          duration: 2,
          xPercent: 2,
          yPercent: 0,
          scale: 1.2,
          opacity: .5,
          ease: 'power1',
          overwrite: 'auto'
        })
      }
    },
  });

  ScrollTrigger.create({
    trigger: ".section-band",
    start: "top bottom",
    end: "1px top",
    onToggle: self => { 
      // console.log("toggled, isActive:", self.isActive)
      if(self.isActive) {
        gsap.to(".bg-img", {
          duration: 2,
          xPercent: -3,
          yPercent: 3,
          scale: 1.3,
          opacity: .5,
          ease: 'power1',
          overwrite: 'auto'
        })
      }
    },
  });

  ScrollTrigger.create({
    trigger: ".section-flexible",
    start: "top+=25% bottom",
    end: "bottom+=25% bottom",
    onToggle: self => { 
      // console.log("toggled, isActive:", self.isActive)
      if(self.isActive) {
        gsap.to(".bg-img", {
          duration: 2,
          xPercent: 0,
          yPercent: 0,
          scale: 1,
          opacity: .5,
          ease: 'power1',
          overwrite: 'auto'
        })
      }
    },
  });

  ScrollTrigger.create({
    trigger: ".section-speed",
    start: "top+=50% bottom",
    // end: "bottom+=400% bottom",
    // markers: true,
    onToggle: self => { 
      // console.log("toggled, isActive:", self.isActive)
      if(self.isActive) {
        gsap.to(".bg-img", {
          duration: .5,
          xPercent: 0,
          yPercent: 0,
          scale: 3,
          opacity: 0,
          ease: 'power1',
          overwrite: 'auto'
        })
      }
    },
  });

  ScrollTrigger.create({
    trigger: ".section-video",
    start: "top bottom",
    end: "bottom bottom",
    // markers: true,
    onEnter: () => {
      gsap.fromTo(".bg-img", {
        scale: 3,
        opacity: 0,
      }, {
        duration: .5,
        xPercent: 0,
        yPercent: 0,
        scale: 1,
        opacity: 0.5,
        ease: 'power1',
        overwrite: 'auto'
      })
    },
    onLeaveBack: () => {
      gsap.to(".bg-img", {
        duration: .5,
        xPercent: 0,
        yPercent: 0,
        scale: 3,
        opacity: 0,
        ease: 'power1',
        overwrite: 'auto'
      })
    },
  });

  ScrollTrigger.create({
    trigger: ".section-acceleration",
    start: "center-=5% bottom",
    end: "center+=5% bottom",
    onToggle: self => { 
      // console.log("toggled, isActive:", self.isActive)
      if(self.isActive) {
        gsap.to(".bg-img", {
          duration: 2,
          xPercent: 0,
          yPercent: -10,
          scale: 1.3,
          opacity: .5,
          ease: 'power1',
          overwrite: 'auto'
        })
      }
    },
  });

  ScrollTrigger.create({
    trigger: ".section-boundaries",
    start: "top bottom",
    end: "bottom+=50% bottom",
    // markers: true,
    onToggle: self => { 
      // console.log("toggled, isActive:", self.isActive)
      if(self.isActive) {
        gsap.to(".bg-img", {
          duration: 2,
          xPercent: 0,
          yPercent: 0,
          scale: 1.4,
          opacity: .5,
          ease: 'power1',
          overwrite: 'auto'
        })

        gsap.to(".section-boundaries", {
          duration: 2,
          opacity: 1,
          ease: 'power1',
          overwrite: 'auto'
        })
      }
    },
    // onLeave: ({isActive}) => {
    //   if(!isActive) {
    //     gsap.to(".bg-img", {
    //       duration: .5,
    //       xPercent: 0,
    //       yPercent: 0,
    //       scale: 3,
    //       opacity: 0,
    //       ease: 'power1',
    //       overwrite: 'auto'
    //     })

    //     gsap.to(".section-boundaries", {
    //       duration: .5,
    //       opacity: 0,
    //       ease: 'power1',
    //       overwrite: 'auto'
    //     })
    //   }
    // },
  });

})();